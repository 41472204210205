.profile-image{
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.light-divider {
    border: none;
    height: 1px;
    background-color: #e0e0e0;  /* Light gray color - you can adjust this */
    margin: 10px 0;
  }