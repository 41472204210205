.header {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.menu-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 12px;
}

.menu-item {
  background-color: #f5f5f5;
  padding: 12px 24px;
  border-radius: 100px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.menu-item:hover {
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px);
}

.menu-item a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

/* More pronounced active state */
.menu-item.active {
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

.menu-item.active a {
  font-weight: 600;
  color: #000;
}

