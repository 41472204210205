.main-container {
  display: flex;
  width: 100%;
  max-width: 1200px; /* Optional: limits maximum width */
  margin: 0 auto; /* Centers the container */
  padding: 20px;
  gap: 32px; /* Space between columns */
  text-align: left;
}

.content-column {
  flex: 3; /* Takes up 3/4 of the available space */
  min-width: 0; /* Prevents flex items from overflowing */
  animation: fadeIn 0.3s ease-in-out;
}

.sidebar-column {
  flex: 1; /* Takes up 1/4 of the available space */
  min-width: 0; /* Prevents flex items from overflowing */
  animation: fadeIn 0.3s ease-in-out;
  text-align: left;
}

.blog-container {
  text-align: left;
  padding: 40px;
}

.blog-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog-title {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  text-decoration: none;
  display: block;
  margin: 20px 0;
  cursor: pointer;
}

.blog-title:hover {
  opacity: 0.7;
}

/* Make it responsive for mobile devices */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  
  .content-column,
  .sidebar-column {
    flex: 1;
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
