.project-details {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 2rem;
    color: #333;
    line-height: 1.6;
}

/* Headings */
.project-details h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #1a1a1a;
}

.project-details h2 {
    font-size: 2rem;
    margin: 2rem 0 1rem;
    color: #1a1a1a;
}

.project-details h3 {
    font-size: 1.5rem;
    margin: 1.5rem 0 0.75rem;
    color: #1a1a1a;
}

/* Paragraphs and Lists */
.project-details p {
    margin-bottom: 1.2rem;
}

.project-details ul, 
.project-details ol {
    margin: 1rem 0;
    padding-left: 2rem;
}

.project-details li {
    margin-bottom: 0.5rem;
}

/* Links */
.project-details a {
    color: #590004;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.2s ease;
}

.project-details a:hover {
    border-bottom-color: #590004;
}

/* Code blocks */
.project-details pre {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin: 1.5rem 0;
}

.project-details code {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f5f5f5;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-size: 0.9em;
}

/* Blockquotes */
.project-details blockquote {
    border-left: 4px solid #590004;
    margin: 1.5rem 0;
    padding-left: 1rem;
    color: #666;
    font-style: italic;
}

/* Images */
.project-details img {
    max-width: 100%;
    height: auto;
    margin: 1.5rem 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Tables */
.project-details table {
    width: 100%;
    border-collapse: collapse;
    margin: 1.5rem 0;
}

.project-details th,
.project-details td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: left;
}

.project-details th {
    background-color: #f5f5f5;
    font-weight: bold;
}

/* Horizontal Rule */
.project-details hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 2rem 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .project-details {
        padding: 0 1rem;
    }

    .project-details h1 {
        font-size: 2rem;
    }

    .project-details h2 {
        font-size: 1.75rem;
    }

    .project-details h3 {
        font-size: 1.25rem;
    }
}

