.project-card {
    display: flex;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .project-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 4px;
  }
  
  .project-content {
    flex: 1;
  }
  
  .project-title {
    margin: 0 0 0.5rem 0;
    color: #1a1a1a;
  }
  
  .authors-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }
  
  .author {
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }
  
  .author-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .annotations {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .conference {
    font-weight: 500;
  }
  
  .award {
    background-color: #8b1f1f;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  
  .project-links {
    display: flex;
    gap: 1rem;
  }
  
  .link {
    color: #0066cc;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }